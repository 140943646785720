export const breakfastItems = [
  {
    title: "",
    description: "* all sandos are served with one side dish, GF bread +2 *",
    price: "",
    addon: "",
    section: "Brekkie Sando",
    key: "menuItem293457a",
  },
  {
    title: "Farmhouse",
    description: "garlic butter, egg, monterey jack, tomato, arugula",
    price: "13.50",
    addon: "",
    section: "Brekkie Sando",
    key: "menuItem29345",
  },
  {
    title: "Western",
    description:
      "chipotle aioli, egg, bacon, roasted peppers, avocado, hash-brown, parmesan",
    price: "16.50",
    addon: "",
    section: "Brekkie Sando",
    key: "menuItem29332",
  },

  {
    title: "Classic",
    description:
      "garlic butter, egg, monterey jack, hash brown, choice of meat",
    price: "14.50",
    addon: "bacon, sausage, avocado",
    section: "Brekkie Sando",
    key: "menuItem29367",
  },
  {
    title: "Godfather",
    description: "sausage patty, egg, roasted peppers, provolone",
    price: "14.50",
    addon: "",
    section: "Brekkie Sando",
    key: "menuItem29389",
  },
  {
    title: "Korean Egg Drop",
    description:
      "marinated bulgogi, caramelized onion, egg, cheddar, kewpie mayo, gochujang sauce (mild or spicy)",
    price: "16.50",
    addon: "",
    section: "Brekkie Sando",
    key: "menuItem29396",
  },
  {
    title: "Smoked Salmon",
    description:
      "smoked salmon, egg, fresh dill, cream cheese, pickled onion, arugula",
    price: "17.75",
    addon: "",
    section: "Brekkie Sando",
    key: "menuItem29343",
  },
  {
    title: "Avocado Toast",
    description: "smashed avocado, lime, pickled onion, arugula, walnuts",
    price: "12.50",
    addon: "",
    section: "Brekkie Plates",
    key: "menuItem293564",
  },
  {
    title: "Hong Kong Toast",
    description:
      "peanut butter on milkbread, fried until crispy, served with condensed milk",
    price: "12",
    addon: "",
    section: "Brekkie Plates",
    key: "menuItem29366",
  },
  {
    title: "French Toast",
    description:
      "brioche bread from Le Banh, served with mixed berries, maple syrup, powdered sugar",
    price: "16.50",
    addon: "",
    section: "Brekkie Plates",
    key: "menuItem29377",
  },
  {
    title: "Karrage and Waffles",
    description:
      "belgian waffle, Japanese fried chicken, served with maple syrup",
    price: "16.50",
    addon: "",
    section: "Brekkie Plates",
    key: "menuItem29398",
  },
  {
    title: "Ricotta Hotcakes",
    description:
      "served with Nutella and banana or maple syrup and mixed berries",
    price: "15.50",
    addon: "",
    section: "Brekkie Plates",
    key: "menuItem29398",
  },
  {
    title: "Blossom Brekkie",
    description:
      "bacon, sausage, cherry tomato, two eggs, asparagus, hash brown, jam, japanese milk bread toast",
    price: "16.50",
    addon: "",
    section: "Brekkie Plates",
    key: "menuItem29398",
  },
  {
    title: "Yogurt Bowl",
    description: "yogurt, assorted fruits, granola, almonds",
    price: "11.50",
    addon: "",
    section: "Brekkie Plates",
    key: "menuItem29398",
  },
  // {
  //   title: "Blossom Benny",
  //   description:
  //     "smoked salmon, poached egg, pickled onion, capers, dill, hollandaise sauce",
  //   price: "17.50",
  //   addon: "",
  //   section: "Brekkie Plates",
  //   key: "menuItem29398",
  // },
  {
    title: "mac salad",
    description: "",
    price: "4.50",
    addon: "",
    section: "sides",
    key: "menuItem29398",
  },
  {
    title: "cucumber kimchi",
    description: "",
    price: "5.50",
    addon: "",
    section: "sides",
    key: "menuItem29398",
  },
  {
    title: "lotus root chips",
    description: "",
    price: "6.50",
    addon: "",
    section: "sides",
    key: "menuItem29398",
  },
  {
    title: "fresh cut sweet fries",
    description: "",
    price: "5.50",
    addon: "",
    section: "sides",
    key: "menuItem29398",
  },
  {
    title: "fresh cut fries",
    description: "",
    price: "5.00",
    addon: "",
    section: "sides",
    key: "menuItem29398",
  },
];

export const desertItems = [
  {
    title: "",
    description: " * flavors are on rotation * ",
    price: "",
    addon: "",
    section: "Souffle Pancake",
    key: "menuItem6553542",
  },
  {
    title: "Original",
    description: "whipped cream and powdered sugar",
    price: "15",
    addon: "",
    section: "Souffle Pancake",
    key: "menuItem6532",
  },
  {
    title: "Biscoff",
    description: "biscoff sauce, cookie crumbles, vanilla ice cream",
    price: "15.50",
    addon: "",
    section: "Souffle Pancake",
    key: "menuItem65143",
  },
  {
    title: "Strawberry",
    description: "strawberry cream and jam sauce",
    price: "15.50",
    addon: "",
    section: "Souffle Pancake",
    key: "menuItem65143",
  },
  {
    title: "Matcha",
    description: "matcha cream, dusted with matcha powder, strawberries",
    price: "15.50",
    addon: "",
    section: "Souffle Pancake",
    key: "menuItem655542",
  },
  {
    title: "",
    description: "only served on Thursday - Sunday",
    price: "",
    addon: "",
    section: "Souffle Pancake",
    key: "menuItem623542",
  },
];

export const lunchItems = [
  {
    title: "Katsu Sando",
    description: "chicken katsu, cucumber kimchi, katsu sauce, cabbage salad",
    price: "17.50",
    addon: "",
    section: "Lunch Sando",
    key: "menuItem92463",
  },
  {
    title: "Bulgogi Sando",
    description:
      "marinated bulgogi, caramelized onions, roasted red pepper, mozzarella cheese, gochujang sauce (mild or spicy)",
    price: "18.50",
    addon: "",
    section: "Lunch Sando",
    key: "menuItem922345",
  },
  {
    title: "Turkey BLT",
    description:
      "applewood smoked bacon, turkey, lettuce, monterey jack, tomato, spicy mayo",
    price: "16.50",
    addon: "",
    section: "Lunch Sando",
    key: "menuItem92732",
  },
  // {
  //   title: "Smoked Salmon bowl",
  //   description:
  //     "mixed greens, rice, smoked salmon, chili oil, avocado, cucumber, soy sauce, sesame seeds",
  //   price: "16.50",
  //   addon: "",
  //   section: "Bowls",
  //   key: "menuItem923456",
  // },
  {
    title: "Protein Bowl",
    description:
      "grilled chicken, avocado, poached egg, squash, pickled onion, white rice, mixed greens, ahi verde",
    price: "17",
    addon: "",
    dietery: "GF",
    section: "Bowls",
    key: "menuItem925437",
  },
  // {
  //   title: "Ceasar Salad",
  //   description: "chopped romaine, parmesan, house made ceasar dressing",
  //   price: "14.50",
  //   addon: "",
  //   section: "Bowls",
  //   key: "menuItem92234",
  // },
  {
    title: "Vegan Kimchi Bowl",
    description:
      "tofu, pickled daikon & carrot, cucumber, avocado, kimchi, green onion, sesame, white rice",
    price: "15.50",
    addon: "",
    dietery: "GF",
    section: "Bowls",
    key: "menuItem92234",
  },
  {
    title: "Chinese Chicken Salad",
    description:
      "red & green cabbage, tangerines, bean sprouts, cashews, sesame seeds, cucumbers, grilled chicken, puffed rice, spicy peanut sauce",
    price: "17",
    addon: "",
    section: "Bowls",
    key: "menuItem92234",
  },
  // {
  //   title: "House Garden Salad",
  //   description:
  //     "mixed greens, cherry tomato, avocado, house made ginger dressing",
  //   price: "8.75",
  //   addon: "",
  //   section: "Bowls",
  //   key: "menuItem92234",
  // },
];

export const beverageItem = [
  {
    title: "",
    description: "* add a shot of alcohol for 4.50 *",
    price: "",
    addon: "",
    section: "Mocktails",
    key: "menuItem234hy54",
  },
  {
    title: "Nojito",
    description: "mint, simple syrup, lime, seltzer",
    price: "6",
    addon: "",
    section: "Mocktails",
    key: "menuItem234hy54",
  },
  {
    title: "Purple Haze",
    description: "butterly pea tea, lemonade",
    price: "8",
    addon: "",
    section: "Mocktails",
    key: "menuItem234hy54",
  },
  {
    title: "Spirited Away",
    description: "green tea, honey, yuzu marmalade",
    price: "9",
    addon: "",
    section: "Mocktails",
    key: "menuItem234hy54",
  },
  {
    title: "Lychee Dragon",
    description: "lychee, lemon, mint, seltzer",
    price: "6",
    addon: "",
    section: "Mocktails",
    key: "menuItem234hy54",
  },
  {
    title: "Matcha Latte",
    description: `(hot or iced) 10 oz, (iced) 12 oz`,
    price: "6 - 6.50",
    addon: "",
    section: "Matcha",
    key: "menuItem234hy54",
  },
  {
    title: "Matcha Tea",
    description: `(hot) 10 oz`,
    price: "5",
    addon: "",
    section: "Matcha",
    key: "menuItem234hy54",
  },
  {
    title: "Green Tangerine Matcha",
    description: `tangerine pulps, sparkling water`,
    price: "7",
    addon: "",
    section: "Matcha",
    key: "menuItem234hy54",
  },
  // {
  //   title: "Butterfly Pea",
  //   description: "Butterly Pea tea, lemonade",
  //   price: "4.25",
  //   addon: "",
  //   section: "Mocktails",
  //   key: "menuItem234hy54",
  // },
  // {
  //   title: "Spirited Away",
  //   description:
  //     "An enchanting blend of green tea, tropical juices, honey, and yuzu, offering a sweet and tangy flavor profile.",
  //   price: "4.25",
  //   addon: "",
  //   section: "Mocktails",
  //   key: "menuItem234hy54",
  // },
  // {
  //   title: "Seasonal Drinks",
  //   description:
  //     "seasonal drinks may include watermelon juice, peach juice, or strawberry",
  //   price: "4.25",
  //   addon: "",
  //   section: "Mocktails",
  //   key: "menuItem234hy54",
  // },

  {
    title: "Limeade",
    description: ``,
    price: "7",
    addon: "",
    section: "Ades",
    key: "menuItem234hy54",
  },
  {
    title: "Yuzu Lemonade",
    description: ``,
    price: "9",
    addon: "",
    section: "Ades",
    key: "menuItem234hy54",
  },
  {
    title: "Mint Lemonade",
    description: ``,
    price: "7",
    addon: "",
    section: "Ades",
    key: "menuItem234hy54",
  },
  {
    title: "Strawberry Lemonade",
    description: ``,
    price: "9",
    addon: "",
    section: "Ades",
    key: "menuItem234hy54",
  },
  // {
  //   title: "Spritz Lemonade",
  //   description: ``,
  //   price: "7",
  //   addon: "",
  //   section: "Ades",
  //   key: "menuItem234hy54",
  // },
];
export const coffeeItem = [
  {
    title: "Syrups",
    description: "vanilla, caramel, hazelnut, honey, agave",
    price: "+0.75",
    addon: "",
    section: "Add Ons",
    key: "menuItem234hy54",
  },
  {
    title: "Milks",
    description: "oat, whole, almond, soy, 1%, half & half",
    price: "+ 0.35 - 0.95",
    addon: "",
    section: "Add Ons",
    key: "menuItem234hy54",
  },

  {
    title: "Latte",
    description: "(hot & iced) 10 oz, (iced) 12 oz",
    price: "5 - 6",
    addon: "",
    section: "Lattes",
    key: "menuItem234hy54",
  },
  {
    title: "Tiramisu Shaken Espresso",
    description: "iced",
    price: "6.50",
    addon: "",
    section: "Lattes",
    key: "menuItem234hy54",
  },
  {
    title: "Brown Sugar Latte",
    description: "(hot & iced) 10 oz, (iced) 12 oz",
    price: "5.50 - 6.50",
    addon: "",
    section: "Lattes",
    key: "menuItem234hy54",
  },
  {
    title: "Cappucino",
    // description: "hot 10 oz",
    price: "4.75",
    addon: "",
    section: "Coffee",
    key: "menuItem234hy54",
  },
  {
    title: "Americano",
    // description: "(hot & iced) 10 oz, (iced) 12 oz",
    price: "4.50 - 5.50",
    addon: "",
    section: "Coffee",
    key: "menuItem234hy54",
  },
  {
    title: "Flat White",
    // description: "(hot) 10 oz",
    price: "5.50",
    addon: "",
    section: "Coffee",
    key: "menuItem234hy54",
  },
  {
    title: "Cortado",
    description: "",
    price: "4.25",
    addon: "",
    section: "Coffee",
    key: "menuItem234hy54",
  },
  {
    title: "Espresso",
    // description: "by shot",
    price: "3.50",
    addon: "",
    section: "Coffee",
    key: "menuItem234hy54",
  },
  {
    title: "Traditional Macchiato",
    // description: "2 oz",
    price: "4",
    addon: "",
    section: "Coffee",
    key: "menuItem234hy54",
  },
  {
    title: "Drip Coffee",
    description: "bottomless",
    price: "5",
    addon: "",
    section: "Coffee",
    key: "menuItem234hy54",
  },
  {
    title: "Mocha",
    // description: "(hot or iced) 10 oz, (iced) 12 oz",
    price: "5.50 - 6.25",
    addon: "",
    section: "Coffee",
    key: "menuItem234hy54",
  },
  // {
  //   title: "Matcha",
  //   description: "",
  //   price: "6 - 6.50",
  //   addon: "",
  //   section: "Matcha",
  // },
  // {
  //   title: "Matcha Tea",
  //   description: "",
  //   price: "5",
  //   addon: "",
  //   section: "Matcha",
  // },
  // {
  //   title: "Green Tangerine Matcha",
  //   description: "",
  //   price: "7",
  //   addon: "",
  //   section: "Matcha",
  // },
];
export const alcoholItem = [
  {
    title: "Mimis",
    description: "fresh orange juice, champagne",
    price: "11",
    addon: "",
    section: "Alcohol",
    key: "menuItem237634",
  },
  {
    title: "Bloody Mary",
    description: "Zing Zang, vodka",
    price: "14",
    addon: "",
    section: "Alcohol",
    key: "menuItem234345",
  },
  {
    title: "In Bloom",
    description: "Soju, Calpico yogurt, sprite, strawberry puree",
    price: "14",
    addon: "",
    section: "Alcohol",
    key: "menuItem234i76",
  },
  {
    title: "Morning Mule",
    description: "orange juice, ginger beer, vodka",
    price: "12",
    addon: "",
    section: "Alcohol",
    key: "menuItem234hy54",
  },
  {
    title: "Espresso Martini",
    description: "coffee liqueur, espresso, vodka, option of baileys(+$1)",
    price: "14",
    addon: "",
    section: "Alcohol",
    key: "menuItem234hy54",
  },
  {
    title: "Tequila Sunrise",
    description: "grapefruit, lime, pomegranate, tequila",
    price: "14",
    addon: "",
    section: "Alcohol",
    key: "menuItem234hy54",
  },
  {
    title: "Bottled Soju",
    description: "original",
    price: "14",
    addon: "",
    section: "Alcohol",
    key: "menuItem234hy54",
  },
];
export const kiddosItem = [
  {
    title: "Kids Karrage",
    description: "8 pieces of fried chicken bites served with fries",
    price: "10.50",
    addon: "",
    section: "For The Kiddos",
    key: "menuItem234hy54",
  },
  {
    title: "Grilled Cheese",
    description: "milk bread with melted cheese served with fries or fruit",
    price: "8",
    addon: "",
    section: "For The Kiddos",
    key: "menuItem234hy54",
  },
  {
    title: "Waffles",
    description: "two pieces of our signature waffles",
    price: "6.50",
    addon: "add chocolate chips +.50",
    section: "For The Kiddos",
    key: "menuItem234hy54",
  },
  {
    title: "Fruit Bowl",
    description: "strawberry, blueberry, banana, seasonal fruits",
    price: "7.50",
    addon: "",
    section: "For The Kiddos",
    key: "menuItem234hy54",
  },
];

export const beverageSection = [
  {
    title: "Mocktails",
  },
  {
    title: "Matcha",
  },
  {
    title: "Ades",
  },
];

export const coffeeSection = [
  {
    title: "Coffee",
  },
  {
    title: "Lattes",
  },
  // {
  //   title: "Matcha",
  // },
  {
    title: "Add Ons",
  },
];

export const lunchSection = [
  {
    title: "Bowls",
  },
  {
    title: "Lunch Sando",
  },
];

export const breakfastSection = [
  {
    title: "Brekkie Plates",
  },
  {
    title: "Brekkie Sando",
  },
  {
    title: "sides",
  },
];

export const desertSection = [
  {
    title: "Souffle Pancake",
  },
];
export const alcoholSection = [
  {
    title: "Alcohol",
  },
];
export const kiddosSection = [
  {
    title: "For The Kiddos",
  },
];
